import React from "react";
import * as Chakra from "@chakra-ui/react";

export const HeadingMedium = (props: Chakra.TextProps): React.ReactElement => {
  return (
    <Chakra.Text
      as="h3"
      fontWeight="500"
      fontSize="28px"
      lineHeight="32px"
      letterSpacing="0.22px"
      {...props}
    />
  );
};
