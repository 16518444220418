import React from "react";
import * as Chakra from "@chakra-ui/react";

export const BodyText = (props: Chakra.TextProps): React.ReactElement => {
  return (
    <Chakra.Text
      color="#000"
      fontSize="14px"
      lineHeight="20px"
      letterSpacing="0.11px"
      maxWidth="600px"
      pb="35px"
      {...props}
    />
  );
};
