import React from "react";
import * as Chakra from "@chakra-ui/react";
import { Link, PageProps } from "gatsby";
import { Seo } from "../components/seo";
import { ModuleContainer } from "../components/module-container";
import { HeadingMedium } from "../components/heading-medium";
import { BodyText } from "../components/body-text";
import { GatsbyImageSVGFallback } from "../components/gatsby-image-svg-fallback";
import NextPageButton from "../components/next-page-button";
import FadeIn from "react-fade-in";

import Alchemy from "../images/books/alchemy.jpg";
import CopywritingSecrets from "../images/books/copywriting-secrets.jpg";
import ExperimentationWorks from "../images/books/experimentation-works.jpg";
import PredictablyIrrational from "../images/books/predictably-irrational.jpg";
import RunningLean from "../images/books/running-lean.jpg";
import TestingBusinessIdeas from "../images/books/testing-business-ideas.jpg";
import TheBiggestBluff from "../images/books/the-biggest-bluff.jpg";
import TheMomTest from "../images/books/the-mom-test.jpg";
import ThePowerOfExperiments from "../images/books/the-power-of-experiments.jpg";
import ThinkingFastAndSlow from "../images/books/thinking-fast-and-slow.jpg";
import ThinkingInBets from "../images/books/thinking-in-bets.jpg";

const Index = ({ location }: PageProps): React.ReactElement => {
  const data = [
    {
      title: "Predictably Irrational",
      link:
        "https://www.amazon.com/Predictably-Irrational-Revised-Expanded-Decisions/dp/0061353248",
      image: PredictablyIrrational,
    },
    {
      title: "Thinking, Fast and Slow",
      link:
        "https://www.amazon.com/Thinking-Fast-Slow-Daniel-Kahneman/dp/0374533555",
      image: ThinkingFastAndSlow,
    },
    {
      title: "Running Lean",
      link:
        "https://www.amazon.com/Running-Lean-Iterate-Plan-Works/dp/1449305172",
      image: RunningLean,
    },
    {
      title: "Alchemy",
      link:
        "https://www.amazon.com/Alchemy-Curious-Science-Creating-Business/dp/0062388428",
      image: Alchemy,
    },
    {
      title: "The Mom Test",
      link:
        "https://www.amazon.com/The-Mom-Test-Rob-Fitzpatrick-audiobook/dp/B07RJZKZ7F/",
      image: TheMomTest,
    },
    {
      title: "The Biggest Bluff",
      link:
        "https://www.amazon.com/Biggest-Bluff-Learned-Attention-Master/dp/052552262X",
      image: TheBiggestBluff,
    },
    {
      title: "Experimentation Works",
      link:
        "https://www.amazon.com/Experimentation-Works-Surprising-Business-Experiments/dp/163369710X",
      image: ExperimentationWorks,
    },
    {
      title: "The Power of Experiments",
      link:
        "https://www.amazon.com/Power-Experiments-Decision-Making-Data-Driven/dp/0262043874",
      image: ThePowerOfExperiments,
    },
    {
      title: "Thinking in Bets",
      link:
        "https://www.amazon.com/Thinking-Bets-Making-Smarter-Decisions/dp/0735216355",
      image: ThinkingInBets,
    },
    {
      title: "Copywriting Secrets",
      link:
        "https://www.amazon.com/Copywriting-Secrets-Everyone-Clicks-Profits/dp/1640854622",
      image: CopywritingSecrets,
    },
    {
      title: "Testing Business Ideas",
      link:
          "https://www.amazon.com/Rapid-Testing-Business-Ideas-Customer/dp/1119551447",
      image: TestingBusinessIdeas,
      colSpan: 2,
    },
  ];

  return (
    <Chakra.Box width="100%">
      <Seo title="Resources" location={location} />
      <ModuleContainer>
        <Chakra.Box w="100%" maxWidth="1400px" m="auto">
          <HeadingMedium maxWidth="350px" pb="35px">
            Resources
          </HeadingMedium>
          <BodyText maxWidth="590px">
            Below is a curated list of books and resources that I’ve found
            helpful in advancing my understanding in behavioural science,
            decision-making and experimentation.
          </BodyText>
          <Chakra.SimpleGrid
            as={FadeIn}
            columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
            gap="60px"
            pb="60px"
          >
            {data.map(({ title, link, image, colSpan }, i) => (
              <Chakra.GridItem
                key={i}
                as="a"
                href={link}
                target="_blank"
                colSpan={colSpan ? colSpan : 1}
              >
                <GatsbyImageSVGFallback
                  transition="0.3s"
                  _hover={{ transform: "translateY(-8px)" }}
                  shadow="md"
                  src={image ? image : undefined}
                />
                <Chakra.Text
                  pt="15px"
                  fontSize="12px"
                  lineHeight="20px"
                  letterSpacing="0.1px"
                >
                  {title}
                </Chakra.Text>
              </Chakra.GridItem>
            ))}
          </Chakra.SimpleGrid>
        </Chakra.Box>
        <NextPageButton as={Link} to="/finish">
          Finish
        </NextPageButton>
      </ModuleContainer>
    </Chakra.Box>
  );
};

export default Index;
